import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import Offerta from '../components/Offerta'
// import Header from '../components/Header'
import Footer from '../components/Footer'

const SecondPage = () => (
  <Layout>
    <header id="header">
    <div className="offerta-logo">
     <Link to="/" ><span className="icon fa-handshake-o fa-2x "></span> </Link> 
    </div>

    </header>
    <Offerta/>
    <Footer/>
  </Layout>
)

export default SecondPage
